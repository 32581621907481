.reset-button {
    position: fixed;
    bottom: 3.5rem;
    right: 0.5rem;
    box-shadow: 2px 2px 6px rgba(82, 80, 68, 1);
}

@media screen and (min-width: 600px) {
    .reset-button {
        bottom: 0.5rem;
        font-size: 0.8rem;
    }
}
