.theme-box {
    box-shadow: 1px 1px 5px rgba(10, 10, 10, 0.4);
}

.theme-actions button {
    padding: 0.5rem;
    width: 46%;
}

.theme-controls button {
    padding: 1rem;
}

:is(.theme-controls, .theme-actions[data-owner="owner"], .theme-actions[data-user="none"])
    button {
    width: 100%;
}

.theme-controls[data-owner="owner"] button {
    width: 29.8%;
}

@media screen and (min-width: 600px) and (max-width: 767px) {
    .theme-controls[data-owner] button {
        width: 100%;
    }
}

@media screen and (min-width: 600px) {
    .theme-controls button {
        padding: 0.5rem;
    }
}
