:root {
    --theme-swatch: 3rem;
}

.card-column {
    height: 100%;
}

@media screen and (min-width: 600px) {
    .card-2 .card {
        width: 47%;
        min-height: calc(var(--vh, 1vh) * 80);
    }
}
